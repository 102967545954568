import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {api} from '../../api/apiService';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {formatBackendErrors} from "../../utils/errorUtils";

interface AnalyticProfile {
  id: number;
  name: string;
  description: string;
  created_by: string;
  created_at: string;
}

export const AnalyticProfilesList = () => {
  const [profiles, setProfiles] = useState<AnalyticProfile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchProfiles = async () => {
    try {
      setIsLoading(true);
      const res = await api.get('/api/analytic-profiles');
      setProfiles(res);
      console.log(res); // Check the value
    } catch (err) {
      setError('Failed to fetch analytic profiles');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  const handleViewProfile = (id: number) => {
    navigate(`/analytic-profiles/${id}/edit`);
  };

  const handleDeleteProfile = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this analytic profile?')) {
      api.delete(`/api/analytic-profiles/${id}`)
        .then((response) => {
          setProfiles((prev) => prev.filter((profile) => profile.id !== id));
        })
        .catch((error) => setDeleteError(formatBackendErrors(error)));
    }
  };

  if (isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        Loading...
      </div>
    );
  if (error) return <div className="text-center text-danger">{error}</div>;

  return (
    <Box padding={2}>
      {deleteError && (
        <Box marginY={2}>
          <Alert severity="error" onClose={() => setDeleteError(null)}>
            <AlertTitle>Error deleting analytic profile</AlertTitle>
            {deleteError}
          </Alert>
        </Box>
      )}
      <TableContainer component={Paper} style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem'}}>
                Name
              </TableCell>
              <TableCell align="center" style={{fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem'}}>
                Description
              </TableCell>
              <TableCell align="center" style={{fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem'}}>
                Created At
              </TableCell>
              <TableCell align="center" style={{backgroundColor: '#f5f5f5'}}>
                <IconButton onClick={fetchProfiles} aria-label="refresh">
                  <RefreshIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile, index) => (
              <TableRow
                key={profile.id}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                  height: '60px',
                }}
              >
                <TableCell align="center">{profile.name}</TableCell>
                <TableCell align="center">{profile.description}</TableCell>
                <TableCell align="center">
                  {new Intl.DateTimeFormat('en-US', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  }).format(new Date(profile.created_at))}
                </TableCell> <TableCell align="center">
                <Box display="flex" justifyContent="center" gap={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleViewProfile(profile.id)}
                    style={{fontWeight: 'bold', textTransform: 'none'}}
                  >
                    View
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleDeleteProfile(profile.id)}
                    style={{fontWeight: 'bold', textTransform: 'none'}}
                  >
                    Delete
                  </Button>
                </Box>
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};