import React from "react";
import {useNavigate} from "react-router-dom";

// Named export
export function NotFoundPage() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Redirects to the home page
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        boxSizing: "border-box", // Ensure padding is included
      }}
    >
      <img
        src="/images/lost-flock.png"
        alt="Lost Flock"
        style={{
          maxWidth: "90%",
          maxHeight: "50vh", // Ensure image doesn't exceed half the viewport height
          marginBottom: "20px",
        }}
      />
      <h1>Oops! You seem to have lost the flock.</h1>
      <p>We can't find the page you're looking for.</p>
      <button
        onClick={handleGoHome}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "#007BFF",
          color: "#FFF",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Go Back Home
      </button>
    </div>
  );
}
