import { AxiosResponse } from 'axios';

export function formatBackendErrors(response: AxiosResponse | null): string {
  console.log(response);
  if (!response) {
    return 'Network error or no response received.';
  }

  const { status, data } = response;
  console.log(status);
  console.log(data);

  if (status === 400 && typeof data === 'object') {
    // Handle bad request errors (validation errors, etc.)
    return Object.entries(data)
      .map(([field, message]) => `${field}: ${message}`)
      .join('\n');
  }

  if (status === 500) {
    // Handle server errors
    return 'Something went wrong on our end. Please try again. If the issue persists, contact support.';
  }

  if (status === 404) {
    // Handle not found errors
    return 'The requested resource was not found.';
  }

  // Fallback for other statuses
  return `Unexpected error occurred (status code: ${status}). Please try again.`;
}