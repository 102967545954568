import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {api} from '../../api/apiService';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {UserContext} from "../../contexts/UserContext";

interface User {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  role: string;
  profile_picture: string;
}

export const UserList = () => {
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const res = await api.get('/api/users');
      setUsers(res);
    } catch (err) {
      setError('Failed to fetch users');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEditUser = (id: number) => {
    navigate(`/users/${id}/edit`);
  };

  const handleDeleteUser = async (id: number) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await api.delete(`/api/users/${id}`);
        setUsers((prev) => prev.filter((user) => user.id !== id));
      } catch {
        alert('Failed to delete user');
      }
    }
  };

  if (isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        Loading...
      </div>
    );
  if (error) return <div className="text-center text-danger">{error}</div>;

  return (
    <Box padding={2}>
      <TableContainer component={Paper} style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem'}}>
                First Name
              </TableCell>
              <TableCell align="center" style={{fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem'}}>
                Last Name
              </TableCell>
              <TableCell align="center" style={{fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem'}}>
                Email
              </TableCell>
              <TableCell align="center" style={{fontWeight: 'bold', backgroundColor: '#f5f5f5', fontSize: '1rem'}}>
                Actions
              </TableCell>
              <TableCell align="center" style={{backgroundColor: '#f5f5f5'}}>
                <IconButton onClick={fetchUsers} aria-label="refresh">
                  <RefreshIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((currentUser, index) => (
              <TableRow
                key={currentUser.id}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
                  height: '60px',
                }}
              >
                <TableCell align="center">{currentUser.first_name}</TableCell>
                <TableCell align="center">{currentUser.last_name}</TableCell>
                <TableCell align="center">{currentUser.username}</TableCell>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center" gap={1}>
                    {user?.permissions?.users?.update ? (
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => handleEditUser(currentUser.id)}
                        style={{fontWeight: 'bold', textTransform: 'none'}}
                      >
                        Edit
                      </Button>
                    ) : null
                    }
                    {user?.permissions?.users?.delete ? (
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteUser(currentUser.id)}
                        style={{fontWeight: 'bold', textTransform: 'none'}}
                      >
                        Delete
                      </Button>
                    ) : null
                    }
                    {!user?.permissions?.users?.delete && !user?.permissions?.users?.update && (
                      <span>No actions enabled</span>
                    )}
                  </Box>
                </TableCell>
                <TableCell/>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};