import {Box, IconButton} from "@mui/material";
import React from "react";
import ProfilePicture from "./ProfilePicture";
import {AccountCircle} from "@mui/icons-material";

export function AccountIconButton(props: { onClick: (event: any) => any }) {
  return (
    <Box>
      <IconButton onClick={props.onClick}>
        <AccountCircle style={{fontSize: 60}}/>
        {/*<ProfilePicture/>*/}
      </IconButton>
    </Box>
  );
}