import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Popover, Typography} from "@mui/material";
import ProfilePicture from "./ProfilePicture";
import {api} from "../../api/apiService";
import {UserContext} from "../../contexts/UserContext";
import {useNavigate} from "react-router-dom";

export function AccountPopover(props: {
  accountAnchorEl: HTMLElement | null;
  setAccountAnchorEl: (element: HTMLElement | null) => void;
}) {
  const isAccountPopoverOpen = Boolean(props.accountAnchorEl);

  const [profilePic, setProfilePic] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const {user, setUser} = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const response = await api.get("/api/users/me/profile-picture", {responseType: 'blob'});
        const imageUrl = URL.createObjectURL(response);
        setProfilePic(imageUrl);
      } catch (err) {
        console.error("Error fetching profile picture:", err);
        setError("Failed to load profile picture.");
      }
    };

    fetchProfilePicture();
  }, []);

  return (
    <Popover
      open={isAccountPopoverOpen}
      anchorEl={props.accountAnchorEl}
      onClose={() => props.setAccountAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box p={2} style={{width: "300px"}}>
        <Typography variant="body2" style={{fontWeight: "bold"}} align="center">
          {user?.username}
        </Typography>
        <ProfilePicture/>
        {user?.first_name && user?.last_name && (
          <Typography variant="body1" align="center" gutterBottom>
            Welcome, {`${user.first_name} ${user.last_name}`}!
          </Typography>
        )}

        {user?.permissions?.users?.create ? (
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              navigate('/users')
            }}
            style={{textTransform: "none"}} // Prevent uppercase transformation
          >
            Users Management
          </Button>
        ) : null
        }
        <Button
          variant="text"
          size="small"
          fullWidth
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
          style={{textTransform: "none", marginTop: "8px"}} // Prevent uppercase and add spacing
        >
          Sign Out
        </Button>
      </Box>
    </Popover>
  );
}