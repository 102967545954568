import './App.css';
import React, {useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginComponent from "./components/LoginComponent";
import {Outlet} from "react-router-dom";
import {AuthContext} from "./auth/AuthContext";

const App = () => {
  const {isAuthenticated} = useContext(AuthContext);
  return isAuthenticated ? <Outlet/> : <LoginComponent/>;
};

export default App;
