import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Container, TextField, Typography,} from '@mui/material';
import {api} from '../api/apiService';
import logo from "../logo.svg";
import {AuthContext} from "../auth/AuthContext";
import {useNavigate} from "react-router-dom";

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const validateEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);

  const {isAuthenticated} = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const emailError = validateEmail(email) ? '' : 'Invalid email address';
    setError(emailError);

    if (emailError) return;

    setIsLoading(true);
    setMessage('');
    try {
      const base_url = window.location.origin; // Get the base URL
      await api.post('/api/auth/forgot-password', {email, base_url});
      setMessage('If the email is associated with an account, a reset link has been sent.');
    } catch (error: any) {
      console.error('Forgot password failed:', error);
      setMessage('An error occurred while processing your request.');
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  if (isAuthenticated) {
    return null; // Prevent rendering
  }

  return (
    <div>
      <Container className="d-flex flex-column align-items-center" style={{maxWidth: '400px', marginTop: '2rem'}}>
        <Box mb={2}>
          <img src={logo} alt="Company Logo" style={{maxWidth: '100%', height: 'auto'}}/>
        </Box>
        <Typography variant="h5" component="h1" gutterBottom>
          Forgot Password
        </Typography>
        <Typography variant="body2" gutterBottom>
          Enter your email address to receive a password reset link.
        </Typography>
        {isLoading ? (
          <CircularProgress/>
        ) : (
          <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <TextField
              autoComplete="email"
              autoFocus
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
              helperText={error}
            />
            {message && <p className="info-message show">{message}</p>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{marginTop: '1rem'}}
            >
              Submit
            </Button>
            <Box textAlign="center" mt={2}>
              <Button
                variant="text"
                onClick={() => (window.location.href = '/login')}
                style={{textDecoration: 'none', color: '#1976d2'}}
              >
                Back to Login
              </Button>
            </Box>
          </form>
        )}
      </Container>
    </div>
  );
};