import React, { useContext, useState } from 'react';
import { clearTokens } from '../auth/auth';
import { scheduleTokenRefresh } from '../auth/scheduleTokenRefresh';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logo from "../logo.svg";
import { api } from "../api/apiService";
import { UserContext } from "../contexts/UserContext";
import { AuthContext } from "../auth/AuthContext";

const LoginComponent = () => {
  const { login } = useContext(AuthContext);
  const { user, setUser } = useContext(UserContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ email: string; password: string }>({ email: '', password: '' });
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const validateEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);

  const handleSessionExpired = () => {
    clearTokens(); // Clear tokens when the session expires
    window.location.reload(); // Reload the page to force re-login
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const emailError = validateEmail(email) ? '' : 'Invalid email address';
    setErrors({ email: emailError, password: '' });

    if (emailError) return;

    setIsLoading(true);
    setMessage('');

    try {
      const response = await api.post(
        '/api/auth/login',
        { email, password },
        { skipAuth: true }
      );
      login(response.access_token, response.refresh_token);
      api.get("/api/users/me").then(res =>
        setUser(res)
      ).catch(err => {
        console.error("Error fetching user:", err);
      });

      // Schedule token refresh after login
      const tokenExpiryTime = Number(localStorage.getItem('tokenExpiryTime')); // Stored during `storeTokens`
      if (tokenExpiryTime) {
        const expiresIn = tokenExpiryTime - Date.now();
        scheduleTokenRefresh(expiresIn / 1000, handleSessionExpired);
      }

      setMessage('Login successful!');
    } catch (error: any) {
      console.error("Authentication failed:", error);
      setMessage('Authentication failed. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#211D1E',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        className="d-flex flex-column align-items-center"
        style={{ maxWidth: '400px', marginTop: '1rem' }}
      >
        <Box
          mb={2}
          style={{
            transform: 'translateX(-0.4cm)',
          }}
        >
          <img src={logo} alt="Company Logo" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          style={{ color: 'white', fontWeight: 'bold' }}
        >
          Login
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField
              autoComplete="username"
              autoFocus
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#3C3B3D',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ffffff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#ffffff',
                  },
                  '& input': {
                    color: 'white',
                    backgroundColor: '#3C3B3D',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'white',
                },
              }}
            />
            <TextField
              label="Password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#3C3B3D',
                  },
                  '&:hover fieldset': {
                    borderColor: '#ffffff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#ffffff',
                  },
                  '& input': {
                    color: 'white',
                    backgroundColor: '#3C3B3D',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'white',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{
                        backgroundColor: 'transparent',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#3C3B3D',
                        },
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box textAlign="right" mt={1}>
              <a href="/forgot-password" style={{ textDecoration: 'none', color: 'white' }}>
                Forgot Password?
              </a>
            </Box>
            {message && <p className="error-message show">{message}</p>}
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                marginTop: '1rem',
                backgroundColor: 'white',
                color: '#211D1E',
                fontWeight: 'bold',
              }}
            >
              Login
            </Button>
          </form>
        )}
      </Container>
    </div>
  );
};

export default LoginComponent;