import React, { createContext, useState, useEffect } from 'react';
import { userIsLoggedIn, storeTokens, clearTokens } from './auth';

export const AuthContext = createContext({
  isAuthenticated: false,
  login: (accessToken: string, refreshToken: string) => {},
  logout: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(userIsLoggedIn());

  const login = (accessToken: string, refreshToken: string) => {
    storeTokens(accessToken, refreshToken);
    setIsAuthenticated(true);
  };

  const logout = () => {
    clearTokens();
    setIsAuthenticated(false);
  };

  useEffect(() => {
    // Optional: Periodically validate token if required
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};