import React, { useState } from 'react';
import { Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import { api } from '../api/apiService';
import logo from "../logo.svg";
import { useSearchParams, useNavigate } from "react-router-dom";

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const validatePasswords = (): string => {
    if (!password || !confirmPassword) return 'Both fields are required';
    if (password !== confirmPassword) return 'Passwords do not match';
    if (password.length < 8) return 'Password must be at least 8 characters long';
    return '';
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const passwordError = validatePasswords();
    setError(passwordError);

    if (passwordError) return;

    setIsLoading(true);
    setMessage('');
    try {
      await api.post('/api/auth/reset-password', { token, password });
      setMessage('Password has been successfully reset.');
    } catch (error: any) {
      console.error('Reset password failed:', error);
      setMessage('An error occurred while resetting your password.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Container className="d-flex flex-column align-items-center" style={{ maxWidth: '400px', marginTop: '2rem' }}>
        <Box mb={2}>
          <img src={logo} alt="Company Logo" style={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
        <Typography variant="h5" component="h1" gutterBottom>
          Reset Password
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please enter your new password below.
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField
              autoFocus
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error}
            />
            <TextField
              label="Confirm New Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={!!error}
              helperText={error}
            />
            {message && <p className="info-message show">{message}</p>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '1rem' }}
            >
              Submit
            </Button>
          </form>
        )}
      </Container>
    </div>
  );
};