// src/api/apiService.ts
import axiosInstance from './axiosInstance';

export const api = {
  get: async (url: string, config: { params?: object; [key: string]: any } = {}) => {
    try {
      const response = await axiosInstance.get(url, config);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  post: async (url: string, data: any, config = {}) => {
    try {
      const response = await axiosInstance.post(url, data, config);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  put: async (url: string, data: any, config = {}) => {
    try {
      const response = await axiosInstance.put(url, data, config);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  delete: async (url: string) => {
    try {
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};

const handleApiError = (error: any) => {
  if (error.isAxiosError) {
    if (error.response?.status === 401) {
      console.error('Unauthorized or session expired');
    } else if (error.response?.status === 404) {
      console.error('Resource not found');
    } else {
      console.error('API error:', error.message);
    }
  } else {
    console.error('Unexpected error:', error);
  }
  throw error; // Re-throw for application-level handling
};