import React from 'react';
import { Link, Outlet } from 'react-router-dom';

export const ProfilesLayout = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-6">
          <h1 className="text-3xl font-bold text-gray-900">Analytic Profiles</h1>
          <nav className="navbar navbar-expand-lg navbar-light bg-light mt-4">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    🏡 Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/analytic-profiles" className="nav-link">
                    📊 All Profiles
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/analytic-profiles/new" className="nav-link">
                    ➕ Create Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/users" className="nav-link">
                    👥 Users Management
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <main className="container mx-auto px-4 py-8">
        <Outlet />
      </main>
    </div>
  );
};