import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {api} from '../../api/apiService';
import {formatBackendErrors} from '../../utils/errorUtils';

interface UserFormProps {
  mode: 'create' | 'edit';
}

export const UserForm: React.FC<UserFormProps> = ({mode}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [canManageUsers, setCanManageUsers] = useState(false);
  const [analyticProfiles, setAnalyticProfiles] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null); // State for selected profile
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const {userId} = useParams<{ userId: string }>();

  useEffect(() => {
    api.get(`/api/analytic-profiles`)
      .then((res) => {
        setAnalyticProfiles(res);
        // Set default profile if none selected
        if (mode === 'create') {
          setSelectedProfileId(res[0].id);
          console.log("selectedProfileId", selectedProfileId);
        }
      })
      .catch((res) => setError(formatBackendErrors(res)));

    if (mode === 'edit') {
      setIsFetching(true);
      api.get(`/api/users/${userId}`)
        .then((response) => {
          const {first_name, last_name, username, can_manage_users, profile_id} = response;
          setFirstName(first_name);
          setLastName(last_name);
          setUsername(username);
          setCanManageUsers(can_manage_users || false);
          setSelectedProfileId(profile_id || null); // Ensure this sets the correct value
        })
        .catch((err) => {
          setError(formatBackendErrors(err));
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [mode, userId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      first_name: firstName,
      last_name: lastName,
      username,
      can_manage_users: canManageUsers,
      profile_id: selectedProfileId, // Include selected profile ID
    };

    const apiCall = mode === 'create' ? api.post : api.put;
    const endpoint = mode === 'create' ? '/api/users' : `/api/users/${userId}`;

    apiCall(endpoint, payload)
      .then(() => {
        navigate('/users');
      })
      .catch((res) => setError(formatBackendErrors(res)))
      .finally(() => setIsLoading(false));
  };

  if (isFetching) {
    return <div>Loading user data...</div>;
  }

  return (
    <div className="container py-4">
      <h2>{mode === 'create' ? 'Create User' : 'Edit User'}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            autoFocus={true}
            type="text"
            className="form-control"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            disabled={mode === 'edit' && isFetching}
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            type="text"
            className="form-control"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            disabled={mode === 'edit' && isFetching}
          />
        </div>
        <div className="form-group">
          <label htmlFor="username">Email</label>
          <input
            id="username"
            type="email"
            className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={mode === 'edit'}
          />
        </div>
        <div className="form-group">
          <label htmlFor="profileSelect">Analytic Profile</label>
          <select
            id="profileSelect"
            className="form-control"
            value={selectedProfileId || ''}
            onChange={(e) => setSelectedProfileId(e.target.value)}
            required
          >
            {analyticProfiles.map((profile: any) => (
              <option key={profile.id} value={profile.id}>
                {profile.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group form-check mt-3">
          <input
            id="canManageUsers"
            type="checkbox"
            className="form-check-input"
            checked={canManageUsers}
            onChange={(e) => setCanManageUsers(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="canManageUsers">
            Can manage users
          </label>
        </div>
        <div className="mt-5">
          <button
            type="submit"
            className="btn btn-primary m-1"
            disabled={isLoading}
          >
            {isLoading ? (mode === 'create' ? 'Creating...' : 'Saving...') : mode === 'create' ? 'Create User' : 'Save Changes'}
          </button>
          <button
            type="button"
            className="btn btn-secondary m-1"
            onClick={() => navigate('/users')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};