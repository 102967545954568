import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../api/apiService';
import { formatBackendErrors } from '../../utils/errorUtils';

interface AnalyticProfileFormProps {
  mode: 'create' | 'edit';
}

export const AnalyticProfileForm: React.FC<AnalyticProfileFormProps> = ({ mode }) => {
  const MAX_KEYWORDS = 5; // Limit of allowed keywords, easily modifiable

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { profileId } = useParams<{ profileId: string }>();

  useEffect(() => {
    if (mode === 'edit' && profileId) {
      setIsFetching(true);
      api.get(`/api/analytic-profiles/${profileId}`)
        .then((response) => {
          const { name, description, keywords } = response;
          setName(name);
          setDescription(description);
          setKeywords(keywords);
        })
        .catch((err) => setError(formatBackendErrors(err)))
        .finally(() => setIsFetching(false));
    }
  }, [mode, profileId]);

  const handleKeywordAdd = () => {
    if (keywordInput.trim() && !keywords.includes(keywordInput.trim()) && keywords.length < MAX_KEYWORDS) {
      setKeywords((prev) => [...prev, keywordInput.trim()]);
      setKeywordInput('');
    }
  };

  const handleKeywordRemove = (keyword: string) => {
    setKeywords((prev) => prev.filter((kw) => kw !== keyword));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (keywords.length === 0) {
      setError('At least one keyword is required.');
      setIsLoading(false);
      return;
    }

    const payload = {
      name: name,
      description: description || '', // Ensure description is optional
      keywords,
    };

    const apiCall = mode === 'create' ? api.post : api.put;
    const endpoint = mode === 'create' ? '/api/analytic-profiles' : `/api/analytic-profiles/${profileId}`;

    apiCall(endpoint, payload)
      .then(() => navigate('/analytic-profiles'))
      .catch((err) => setError(formatBackendErrors(err)))
      .finally(() => setIsLoading(false));
  };

  if (isFetching) {
    return <div>Loading profile data...</div>;
  }

  return (
    <div className="container py-4">
      <h2>{mode === 'create' ? 'Create Analytic Profile' : 'Edit Analytic Profile'}</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={4}
          />
        </div>
        <div className="form-group">
          <label htmlFor="keywords">Keywords</label>
          <div className="d-flex flex-wrap gap-2">
            {keywords.map((keyword, index) => (
              <span
                key={index}
                className="badge bg-primary text-white d-flex align-items-center"
                style={{ padding: '0.5rem', cursor: 'pointer' }}
                onClick={() => handleKeywordRemove(keyword)}
              >
                {keyword}
                <span className="ms-2">&times;</span>
              </span>
            ))}
          </div>
          <input
            id="keywords"
            type="text"
            className="form-control mt-2"
            value={keywordInput}
            onChange={(e) => setKeywordInput(e.target.value)}
            onKeyDown={(e) => {
              if ((e.key === 'Enter' || e.key === ',') && keywords.length < MAX_KEYWORDS) {
                e.preventDefault();
                handleKeywordAdd();
              }
            }}
            placeholder="Type a keyword and press Enter or Comma"
            disabled={keywords.length >= MAX_KEYWORDS}
          />
          <small className="form-text text-muted">
            {keywords.length}/{MAX_KEYWORDS} keywords added
          </small>
        </div>
        <div className="mt-5">
          <button type="submit" className="btn btn-primary m-1" disabled={isLoading}>
            {isLoading
              ? mode === 'create'
                ? 'Creating...'
                : 'Saving...'
              : mode === 'create'
                ? 'Create Profile'
                : 'Save Changes'}
          </button>
          <button
            type="button"
            className="btn btn-secondary m-1"
            onClick={() => navigate('/analytic-profiles')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};