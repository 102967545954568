import React from "react";
import {api} from "../../api/apiService";

export const handleProfilePicUpdate = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setProfilePic: React.Dispatch<React.SetStateAction<string | null>>
) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Send the image to the backend
      const response = await api.put("/api/users/profile-picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Profile picture updated successfully", response);

      // Update the profile picture preview with the new uploaded file
      const newImageUrl = URL.createObjectURL(file);
      setProfilePic(newImageUrl); // Update the state to reflect the new image
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  }
};