import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {UserProvider} from "./contexts/UserContext";

// Layouts
import {AuthProvider} from "./auth/AuthContext";
import {RouterComponent} from "./components/routerComponent";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <RouterComponent/>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();